<template>
    <div class="container">
        <h1 class="text-algn">人才测评</h1>
        <!--   OPQ     -->
<!--                <div class="row" style="margin-top: 30px;">-->
<!--                    <div class="col-md-6 col-xs-12">-->
<!--                        <div class="left-img">-->
<!--                            <img src="./assets/1.png">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-6 col-xs-12">-->
<!--                        <div class="card" >-->
<!--                            <div class="card-body">-->
<!--                                <h5 class="card-title">管理潜质（OPQ）</h5>-->
<!--                                <p class="card-text">职业素质能力测评（Occupational Personality Questionnaire，简称OPQ）是一种广泛应用于人力资源管-->
<!--                                    理和人才发展领域的心理测评工具。它旨在评估个人在职业环境中的行为特征、性格倾向和潜在能力。OPQ-->
<!--                                    主要通过以下几个方面来测评个人的职业素质：</p>-->
<!--                                <a href="#" class="card-link" @click="handleRouter(1)">立即测评</a>-->
<!--                                <a href="#" class="card-link" data-toggle="modal" data-target="#myModa2">查看介绍</a>-->
<!--                                <div class="pops">共计：80题</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
        <!--    盖洛普    -->
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="left-img">
                    <img src="./assets/2.png">
                </div>
            </div>
            <div class="col-md-6 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">盖洛普</h5>
                        <p class="card-text">针对销售（业务）人员的二十一项基本胜任素质进行细分评估： 沟通表达、人际交往、成就动机、行动力
                            挑战意识、学习能力、解决问题、亲和力、情绪稳定、灵活性、目标意识、主动性、责任心、说服力、压力
                            管理、人际关系、团队协作、自我激励、计划潜能、执行力、时间管理。</p>
                        <a href="#" class="card-link" @click="handleRouter(3)">立即测评</a>
                        <a href="#" class="card-link" data-toggle="modal" data-target="#myModal">查看介绍</a>
                        <div class="pops">共计：186题</div>
                    </div>
                </div>
            </div>
        </div>
        <!--   PDP     -->
<!--        <div class="row" style="margin-top: 30px;">-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--                <div class="left-img">-->
<!--                    <img src="./assets/3.png">-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--                <div class="card">-->
<!--                    <div class="card-body">-->
<!--                        <h5 class="card-title">PDP测试</h5>-->
<!--                        <p class="card-text">-->
<!--                            PDP测评将人的人际风格分为五种：老虎型、孔雀型、猫头鹰型、考拉型、变色龙型。五种类型的人，各具特点。通过进行简易版测试，可以了解自己的行为风格，进而去扬长避短。</p>-->
<!--                        <a href="#" class="card-link" @click="handleRouter(2)">立即测评</a>-->
<!--                        <a href="#" class="card-link" data-toggle="modal" data-target="#myModa2">查看介绍</a>-->
<!--                        <div class="pops">共计：40题</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!--  职业锚      -->
<!--        <div class="row" style="margin-top: 30px;">-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--                <div class="left-img">-->
<!--                    <img src="./assets/4.png">-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-xs-12">-->
<!--                <div class="card">-->
<!--                    <div class="card-body">-->
<!--                        <h5 class="card-title">职业锚测评</h5>-->
<!--                        <p class="card-text">-->
<!--                            侧重职业价值观。主要用于在职员工，已经对自己的职业有所了解，尤其针对处于职业转变期，有机会进行岗位调整时找到真正属于自己的那份职业锚的员工，对自我的定位有个真实的认识。</p>-->
<!--                        <a href="#" class="card-link" @click="handleRouter(4)">立即测评</a>-->
<!--                        <a href="#" class="card-link" data-toggle="modal" data-target="#myModa4">查看介绍</a>-->
<!--                        <div class="pops">共计：41题</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
    <!-- 弹窗 -->
    <div class="modal" id="myModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 弹窗头部 -->
                <div class="modal-header">
                    <h5 class="modal-title">盖洛普</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <!-- 弹窗内容 -->
                <div class="modal-body">
                    <p> 盖洛普（Gallup）人才测评系统是一个评估个人潜力和特质的工具，可以帮助创业者更好地了解自己和团队
                        成员，从而提高团队绩效和创业成功率。以下是盖洛普人才测评系统对创业者的具体帮助：</p>
                    <p> 1. 发掘个人优势：通过评估，创业者可以了解自己的核心优势，如领导力、创新能力、沟通能力等。这有
                        助于他们发挥自己的优势，更好地推动企业发展。</p>
                    <p> 2. 组建合适的团队：盖洛普人才测评系统可以帮助创业者识别具有互补优势的团队成员，从而组建高效、
                        协同的团队。这对于创业成功至关重要。</p>
                    <p>3. 提升团队绩效：了解团队成员的优势和特质，有助于分配合适的角色和任务，提高工作效率和团队协
                        作。同时，创业者可以针对成员的优势和特点，提供定制化的培训和激励措施，提高团队士气和绩效。</p>
                    <p> 4. 优化招聘流程：在招聘新员工时，创业者可以通过盖洛普人才测评系统来评估候选人的潜力和特质，确
                        保招聘到与企业文化和需求相匹配的人才。</p>
                    <p> 5. 个人职业发展规划：通过了解自己的优势和劣势，创业者可以制定更有针对性的职业发展计划，提升自
                        己的领导力和创业能力。</p>
                    <p> 6. 提高领导力：盖洛普人才测评系统可以帮助创业者识别自己在领导力方面的优势和不足，从而提升自己
                        的领导能力，更好地激励和引导团队。</p>
                    <p>
                        总之，盖洛普人才测评系统通过评估个人优势和特质，为创业者提供了有力的支持，帮助他们发挥自身潜
                        力，组建高效团队，提升团队绩效，并优化招聘流程。这些都有助于提高创业成功率和企业竞争力。</p>
                </div>
                <!-- 弹窗底部 -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="myModa2">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 弹窗头部 -->
                <div class="modal-header">
                    <h5 class="modal-title">PDP测试</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <!-- 弹窗内容 -->
                <div class="modal-body">
                    <p> 职业素质能力测评（OPQ）人才测评系统针对个人在职业环境中的行为特征、性格倾向和潜在能力进行评
                        估，对创业者有以下几方面的帮助：</p>
                    <p> 1. 自我认知：通过OPQ评估，创业者可以更深入地了解自己的思维风格、情感特质、人际关系、动机、价
                        值观以及工作风格，从而发挥自己的优势，改进劣势，提升创业成功率。</p>
                    <p> 2. 优化团队构建：OPQ可以帮助创业者识别和选拔具有互补性格和能力的团队成员，以建立高效、协同的
                        团队。了解团队成员的职业素质，有助于合理分配任务、提高团队协作和工作效率。</p>
                    <p>3. 提高招聘效果：在招聘过程中，创业者可以利用OPQ评估候选人的职业素质，从而选拔出与企业文化、
                        岗位需求和团队氛围相匹配的人才，提高招聘效果。</p>
                    <p>4. 培训与发展：了解团队成员的职业素质，创业者可以针对性地为员工提供培训和发展机会，提升员工的
                        技能和能力，增强团队整体实力。</p>
                    <p> 5. 个人成长和职业发展：通过OPQ评估，创业者可以了解自己的职业发展方向和潜力，制定更有针对性的
                        职业规划，提升个人领导力和创业能力。</p>
                    <p> 6. 提升领导力：OPQ测评可以帮助创业者识别自己在领导力方面的优势和不足，从而提升自己的领导能
                        力，更好地激励和引导团队。</p>
                    <p>
                        总之，职业素质能力测评（OPQ）人才测评系统可以帮助创业者在自我认知、团队构建、招聘、培训和发
                        展、个人成长和职业发展、领导力提升等方面取得更好的成效，从而提高创业成功率和企业竞争力。</p>
                </div>
                <!-- 弹窗底部 -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <!--  职业锚  -->
    <div class="modal" id="myModa4">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 弹窗头部 -->
                <div class="modal-header">
                    <h5 class="modal-title">职业锚测试</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <!-- 弹窗内容 -->
                <div class="modal-body">
                    <p> 侧重职业价值观。</p>
                    <p> 主要用于在职员工，已经对自己的职业有所了解，尤其针对处于职业转变期，有机会进行岗位调整时找到真正属于自己的那份职业锚的员工，对自我的定位有个真实的认识。</p>
                </div>
                <!-- 弹窗底部 -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'App',
    data() {
        return {
            token: "",
            uuid :''
        }
    },
    methods: {
        getUrlKey(name) {
            return (
                decodeURIComponent(
                    (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                        decodeURIComponent(location.href)
                        // eslint-disable-next-line no-sparse-arrays
                    ) || [, ""])[1].replace(/\+/g, "%20")
                ) || null
            );
        },
        handleRouter(id) {
            if (id === 1) {
                // opq
                window.open("https://free.findtalent.cn/#/checkboxs?id=7&token=" + this.token + "&eid=115&uuid=" + this.uuid)
            } else if (id === 2) {
                // pdp
                window.open("https://free.findtalent.cn/#/pdp?id=8&token=" + this.token + "&eid=132&uuid=" + this.uuid)
            } else if (id === 3) {
                // 盖雷普
                window.open("https://free.findtalent.cn/#/zyys?id=11&token=" + this.token + "&eid=116&uuid=" + this.uuid)
            }else if(id === 4){
                // 职业锚
                window.open("https://free.findtalent.cn/#/zym?id=3&token=" + this.token + "&eid=133&uuid=" + this.uuid)
            }

        }
    },
    created() {
        this.token = this.getUrlKey("token")
        this.uuid = this.getUrlKey("uuid")
    }

}
</script>

<style>
.text-algn {
    text-align: center;
    margin: 30px 0;
}

.left-img {
    width: 100%;
}

.left-img img {
    height: 236px;
    width: 100%;
}

.card-body {
    min-height: 236px;
    position: relative;
}

.pops {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #999999;
}
</style>
